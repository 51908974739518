import styled from "styled-components"
import searchBackground from "../../../images/search-background.jpg"

export const Main = styled.div`
  background: url(${searchBackground}) top center no-repeat fixed;

  @supports (-webkit-touch-callout: none) {
    background: url(${searchBackground}) center top no-repeat scroll;
    background-size: auto 100vh;
  }
  
  background-position-y: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8.5rem;
  gap: 13px;
  position: relative;
  aspect-ratio: 16/9;
  background-size: cover;

  @media (max-width: 1149px) {
    width: 100%;
    height: 100vh;
    background-size: cover;
  }

  @media (max-width: 550px) {
    gap: 25px;
  }
`

export const Arrow = styled.img`
  width: 12px;
  height: 6px;
  position: absolute;
  left: 50%;
  bottom: 34px;
  z-index: 4;
  cursor: pointer;
`
export const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #030303;
  opacity: 0.6;
  aspect-ratio: 16/9;

  @media (max-width: 1149px) {
    height: 100vh;
    min-height: 650px;

    width: 100%;
  }
`

export const SpanBox = styled.div`
  margin-bottom: 75px;
  width: 100%;
  z-index: 4;

  @media (max-width: 799px) {
    margin-bottom: 0;
  }
`

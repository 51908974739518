import React, { useState, useContext, useEffect, useRef } from "react"
import {
  Main,
  Wrapper,
  StyledInput,
  GoldButton,
  WrapperTitle,
  CombinedInput,
} from "./style"
import { SearchOptionsContext } from "../../contexts/SearchOptionsContext"
import { FlatsContext } from "../../contexts/FlatsContext"
import { navigate } from "gatsby-plugin-react-intl"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
import * as dayjs from "dayjs"
import SelectSearch from "react-select-search"
import { useLocation } from "@reach/router"
import "./select-style.css"

const SearchComponent = () => {
  const priceFromRef = useRef(null)
  const floorageRef = useRef(null)
  const priceToRef = useRef(null)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const propertyType = searchParams.get("propertyType")
  const transactionType = searchParams.get("transactionType")
  const locationParam = searchParams.get("location")
  const floorageRefTo = useRef(null)
  const locationRef = useRef(null)
  const propertyTypeRef = useRef(null)
  const transactionTypeRef = useRef(null)
  const intl = useIntl()
  const lang = intl.locale
  const [data, setData] = useState({})
  const [searchData, setSearchData, sortingType] =
    useContext(SearchOptionsContext)
  const [isPriceSectionActive, setPriceSectionActive] = useState(false)
  const [isFloorageSectionActive, setFloorageSectionActive] = useState(false)
  const isBrowser = typeof window !== "undefined"
  const [flats, loading, filteredFlats, setFilteredFlats] =
    useContext(FlatsContext)

  const handleOnChange = e => {
    const { id, value } = e.target
    setSearchData(prev => ({ ...prev, [id]: value }))
  }

  const handleOnChangeSelect = (name, value) => {
    setSearchData(prev => ({ ...prev, [name]: value }))
    if (name === "propertyType") {
      setPropertyTypeState(value)
    }
    if (name === "transactionType") {
      setTransactionTypeState(value)
    }
    if (name === "location") {
      setLocationState(value)
    }
    if (name !== "location") {
      setSearchData(prev => ({ ...prev, location: "" }))
      setLocationState("")
    }

    setLocationDisplayOptions(false)
    setTransactionTypeDisplayOptions(false)
    setPropertyTypeDisplayOptions(false)
  }

  const query = useStaticQuery(graphql`
    query Search {
      polish: contentfulSearch(node_locale: { eq: "pl-PL" }) {
        adType
        flat
        floorage
        house
        land
        local
        location
        office
        price
        real_estates
        rent
        search
        search_offer
        sell
        from
        to
        allLocations
        allRealEstates
        allTransactions
      }
      english: contentfulSearch(node_locale: { eq: "en-US" }) {
        adType
        flat
        floorage
        house
        land
        local
        location
        office
        price
        real_estates
        rent
        search
        search_offer
        sell
        from
        to
        allLocations
        allRealEstates
        allTransactions
      }
    }
  `)

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
      }
    }
  }, [intl])

  const filterFlats = (
    withLocation = true,
    withPropertyType = true,
    withTransactionType = true
  ) => {
    return flats
      .filter(flat => {
        if (!withPropertyType) {
          return true
        }

        if (searchData.propertyType) {
          if (searchData.propertyType === "all") return flat
          return (
            flat.baseData.propertyType &&
            flat.baseData.propertyType === searchData.propertyType
          )
        } else {
          return flat
        }
      })
      .filter(flat => {
        if (searchData.priceFrom) {
          return flat.baseData.price >= searchData.priceFrom
        } else return flat
      })
      .filter(flat => {
        if (searchData.priceTo) {
          return flat.baseData.price <= searchData.priceTo
        } else return flat
      })
      .filter(flat => {
        if (searchData.floorageFrom) {
          if (flat.landData && flat.landData.floorage) {
            return flat.landData.floorage >= searchData.floorageFrom
          } else {
            return flat.floorage >= searchData.floorageFrom
          }
        } else return flat
      })
      .filter(flat => {
        if (searchData.floorageTo) {
          if (flat.landData && flat.landData.floorage) {
            return flat.landData.floorage <= searchData.floorageTo
          } else {
            return flat.floorage <= searchData.floorageTo
          }
        } else return flat
      })
      .filter(flat => {
        if (!withTransactionType) {
          return true
        }

        if (searchData.transactionType) {
          if (searchData.transactionType === "all") return flat

          return flat.baseData.transaction === searchData.transactionType
        } else return flat
      })
      .filter(flat => {
        if (!withLocation) {
          return true
        }

        if (searchData.location === "all") {
          return flat
        }

        if (searchData.location) {
          const address = `${
            flat.baseData.address.city
              ? flat.baseData.address.city.toLowerCase() + ","
              : ""
          } ${
            flat.baseData.address.district
              ? flat.baseData.address.district.toLowerCase() + ","
              : ""
          } ${
            flat.baseData.address.state
              ? flat.baseData.address.state.toLowerCase()
              : ""
          }`

          return address.includes(searchData.location.toLowerCase())
        } else return flat
      })
  }

  const sortFlatsFunction = (a, b) => {
    const dateFormat = "YYYY-MM-DD HH:MM:ss"
    const sortOrder = sortingType.direction
    const sortType = sortingType.column

    if (sortOrder === "ASC") {
      if (sortType === "PRICE") {
        return a.baseData.price - b.baseData.price
      } else if (sortType === "DATE") {
        return dayjs(a.baseData.addDate, dateFormat).isAfter(
          dayjs(b.baseData.addDate)
        )
          ? 1
          : -1
      }
    } else {
      if (sortType === "PRICE") {
        return b.baseData.price - a.baseData.price
      } else if (sortType === "DATE") {
        return dayjs(b.baseData.addDate, dateFormat).isAfter(
          dayjs(a.baseData.addDate)
        )
          ? 1
          : -1
      }
    }
  }

  const performFlatsFilteringWithSort = () => {
    if (!isBrowser) {
      return
    }

    const filteredFlats = filterFlats()
    const sortedFlats = [...filteredFlats].sort(sortFlatsFunction)

    setFilteredFlats(sortedFlats)
  }

  const onSubmit = () => {
    performFlatsFilteringWithSort()

    if (
      window.location.pathname.includes(
        lang === "pl" ? "wyszukiwarka" : "search"
      ) === false
    ) {
      const urlData = Object.keys(searchData)
        .map(k => `${k}=${encodeURIComponent(searchData[k])}`)
        .join("&")

      console.log(window.location.pathname)

      if (window.location.pathname === "/en/") {
        navigate("/search")
        return
      }

      navigate(
        lang === "pl" ? "/wyszukiwarka/?" + urlData : "/en/search/?" + urlData
      )
    } else {
      const urlData = Object.keys(searchData)
        .map(k => `${k}=${encodeURIComponent(searchData[k])}`)
        .join("&")

      window.history.replaceState(
        "",
        document.title,
        lang === "pl" ? "/wyszukiwarka/?" + urlData : "/en/search/?" + urlData
      )
    }
  }

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      onSubmit()
    }

    trySetFloorageSectionActive()
  }

  const handleKeyDown = e => {
    if (
      !/[0-9]/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault()
    }
  }

  const trySetPriceSectionActive = (data = false) => {
    if (!data) data = searchData

    setPriceSectionActive(data.priceFrom || data.priceTo)
  }

  const trySetFloorageSectionActive = (data = false) => {
    if (!data) data = searchData

    setFloorageSectionActive(data.floorageFrom || data.floorageTo)
  }

  const fetchPropertyTypeOptions = async query => {
    if (loading) return []

    const filteredFlats = filterFlats(true, false, true)

    const optionsAll = [
      // { name: data.real_estates, value: "" },
      { name: data.allRealEstates, value: "all" },
      { name: data.land, value: "land" },
      { name: data.house, value: "house" },
      { name: data.flat, value: "flat" },
      { name: data.local, value: "usable_locale" },
    ]

    const createdOptions = []

    for (const option of optionsAll) {
      if (option.value === "" || option.value === "all") {
        createdOptions.push({
          name: `${option.name} (${filteredFlats.length})`,
          value: option.value,
          renderData: {
            name: option.name,
            count: filteredFlats.length,
          },
        })

        continue
      }

      const count = filteredFlats.filter(
        flat => flat.baseData.propertyType === option.value
      ).length

      createdOptions.push({
        name: `${option.name} (${count})`,
        value: option.value,
        renderData: {
          name: option.name,
          count,
        },
      })
    }

    return createdOptions
  }

  const fetchTransationTypeOptions = async query => {
    if (loading) return []

    const filteredFlats = filterFlats(true, true, false)

    const optionsAll = [
      // { name: `${data.adType}`, value: "" },
      { name: `${data.allTransactions}`, value: "all" },
      { name: `${data.sell} `, value: "sell" },
      { name: `${data.rent}`, value: "rent" },
    ]

    const createdOptions = []

    for (const option of optionsAll) {
      if (option.value === "" || option.value === "all") {
        createdOptions.push({
          name: `${option.name} (${filteredFlats.length})`,
          value: option.value,
          renderData: {
            name: option.name,
            count: filteredFlats.length,
          },
        })

        continue
      }

      const count = filteredFlats.filter(
        flat => flat.baseData.transaction === option.value
      ).length

      createdOptions.push({
        name: `${option.name} (${count})`,
        value: option.value,
        renderData: {
          name: option.name,
          count,
        },
      })
    }

    return createdOptions
  }

  // useEffect(() => {
  //   priceFromRef.current.focus()
  // }, [isPriceSectionActive])

  const fetchLocationOptions = async query => {
    if (loading) return []

    query = query.trim().toLowerCase()

    const groupedFlats = {}

    const filteredFlats = flats

    for (const flat of filteredFlats) {
      const city = flat.baseData.address.city

      if (query != "" && city.toLowerCase().includes(query) === false) continue

      if (city in groupedFlats === false) groupedFlats[city] = 0

      groupedFlats[city]++
    }

    const options = Object.keys(groupedFlats).map(city => ({
      value: city,
      name: `${city}`, // (${groupedFlats[city]}
    }))

    options.unshift({
      value: "all",
      name: data.allLocations,
    })

    // options.unshift({
    //   name: "Wszystkie lokalizacje",
    //   value: "",
    // })

    return options
  }

  const renderSelectOption = (
    optionsProps,
    { renderData: { name, count } },
    optionSnapshot,
    className
  ) => {
    //return <b>Hello World</b>;
    return (
      <button {...optionsProps} className={className} type="button">
        {/* <span style={{ fontFamily: stack }}>{name}</span> */}
        {name}
        {count >= 0 && <span class="select-render-number">{count}</span>}
      </button>
    )
  }

  // Make attempt of searching on load (if some fields are predefinetly filled)
  useEffect(() => {
    const queryData = window.location.search.substring(1)

    if (queryData != "") {
      const searchData = {}

      queryData
        .split("&")
        .map(v => v.split("="))
        .forEach(v => {
          searchData[v[0]] = decodeURIComponent(v.slice(1).join("="))
        })

      setSearchData(searchData)

      trySetPriceSectionActive(searchData)
      trySetFloorageSectionActive(searchData)
    }
  }, [])

  useEffect(() => {
    if (
      !loading &&
      searchData &&
      window.location.pathname.includes(
        lang === "pl" ? "wyszukiwarka" : "search"
      )
    ) {
      setTimeout(() => {
        const hs = (" " + window.location.hash).slice(1)

        const fn = hasHash => {
          if (hasHash && hasHash !== "flats") {
            const transformedHash = hasHash.replace("#", "")

            const el = document.getElementById("flat_" + transformedHash)

            if (el && el != null) {
              el.scrollIntoView(true)
            }
          }
        }

        onSubmit()

        setTimeout(fn(hs), 1000)
      }, 0) // Evil ensurance for updating context of filteredFlats
    }
  }, [loading, searchData])

  const [propertyTypeDisplayOptions, setPropertyTypeDisplayOptions] =
    useState(false)
  const [transactionTypeDisplayOptions, setTransactionTypeDisplayOptions] =
    useState(false)
  const [locationDisplayOptions, setLocationDisplayOptions] = useState(false)

  useEffect(() => {
    const element = document.querySelector(
      "#propertyType > div.select-search__value"
    )

    const listener = function (e) {
      const targetClassName = e.target.className

      if (targetClassName === "select-search__input") {
        const parent = e.target.offsetParent.offsetParent.id

        if (parent === "propertyType") {
          setPropertyTypeDisplayOptions(r => !r)
        }

        if (parent === "transactionType") {
          setTransactionTypeDisplayOptions(r => !r)
        }

        if (parent === "location") {
          setLocationDisplayOptions(r => !r)
        }
      } else {
        setLocationDisplayOptions(false)
        setTransactionTypeDisplayOptions(false)
        setPropertyTypeDisplayOptions(false)
      }
    }

    document.addEventListener("click", listener)

    return () => {
      document.removeEventListener("click", listener)
    }
  }, [])

  useEffect(() => {
    performFlatsFilteringWithSort()
  }, [sortingType])

  const [propertyTypeState, setPropertyTypeState] = useState(propertyType)
  const [transactionTypeState, setTransactionTypeState] =
    useState(transactionType)
  const [locationState, setLocationState] = useState(locationParam)

  return (
    <Main>
      <Wrapper>
        <WrapperTitle id="title">{data.search_offer}</WrapperTitle>
        <div
          id="parentDiv"
          style={{
            border: propertyTypeState
              ? "1px solid transparent"
              : "1px solid #111",
            background:
              "linear-gradient(90deg,#ce9638 5.35%,#eae69c 7.05%, #b6872d 52.34%)",
            // height: propertyTypeRef?.current?.clientHeight + 2,
            height: "calc(100%)",
          }}
        >
          <SelectSearch
            id="propertyType"
            ref={propertyTypeRef}
            onChange={handleOnChangeSelect.bind(this, "propertyType")}
            printOptions={propertyTypeDisplayOptions ? "always" : "never"}
            value={searchData.propertyType}
            options={[]}
            getOptions={fetchPropertyTypeOptions}
            placeholder={data.real_estates}
            renderOption={renderSelectOption}
          />
        </div>
        <div
          style={{
            border: transactionTypeState
              ? "1px solid transparent"
              : "1px solid #111",
            background:
              "linear-gradient(90deg,#ce9638 5.35%,#eae69c 7.05%, #b6872d 52.34%)",
            height: "calc(100%)",
          }}
        >
          <SelectSearch
            id="transactionType"
            printOptions={transactionTypeDisplayOptions ? "auto" : "never"}
            onChange={handleOnChangeSelect.bind(this, "transactionType")}
            value={searchData.transactionType}
            ref={transactionTypeRef}
            options={[]}
            getOptions={fetchTransationTypeOptions}
            placeholder={data.adType}
            renderOption={renderSelectOption}
          />
        </div>
        <div
          style={{
            gridArea: "location",
            border: locationState ? "1px solid transparent" : "1px solid #111",
            background:
              "linear-gradient(90deg,#ce9638 5.35%,#eae69c 7.05%, #b6872d 52.34%)",
            height: "calc(100%)",
          }}
        >
          <SelectSearch
            id="location"
            printOptions={locationDisplayOptions ? "auto" : "never"}
            onChange={handleOnChangeSelect.bind(this, "location")}
            value={searchData.location ? searchData.location : -1}
            ref={locationRef}
            options={[]}
            placeholder={data.location}
            getOptions={fetchLocationOptions}
            search
          />
        </div>

        {/* cena */}
        <CombinedInput id="price" isActive={isPriceSectionActive}>
          <StyledInput
            type="text"
            onClick={() => {
              setPriceSectionActive(true)
              priceFromRef.current.focus()
            }}
            placeholder={data.price}
            className="placeholder"
          />

          <div className="children">
            <div
              style={{
                width: "calc(47.1910%)",
                position: "absolute",
                opacity: isPriceSectionActive ? "1" : "0",
                userSelect: isPriceSectionActive ? "all" : "none",
                background:
                  "linear-gradient(90deg,#ce9638 5.35%,#eae69c 7.05%, #b6872d 52.34%)",
                border: !priceFromRef?.current?.value
                  ? "1px solid #111"
                  : "1px solid transparent",
              }}
            >
              <div
                style={{
                  background: "#111",
                  minHeight: "62px",
                }}
              >
                <StyledInput
                  isActive={isPriceSectionActive}
                  type="text"
                  placeholder={data.from}
                  value={searchData.priceFrom}
                  ref={priceFromRef}
                  onKeyDown={handleKeyDown}
                  onChange={handleOnChange}
                  onFocus={() => setPriceSectionActive(true)}
                  onBlur={() => trySetPriceSectionActive()}
                  id="priceFrom"
                  onKeyUp={handleKeyPress}
                />
              </div>
            </div>
            <div
              style={{
                width: "calc(47.1910%)",
                position: "absolute",
                right: 0,
                visibility: isPriceSectionActive ? "visible" : "hidden",
                background:
                  "linear-gradient(90deg,#ce9638 5.35%,#eae69c 7.05%, #b6872d 52.34%)",
                border: !priceToRef?.current?.value
                  ? "1px solid #111"
                  : "1px solid transparent",
              }}
            >
              <div
                style={{
                  background: "#111",
                  minHeight: "62px",
                }}
              >
                <StyledInput
                  isActive={isPriceSectionActive}
                  type="text"
                  ref={priceToRef}
                  placeholder={data.to}
                  value={searchData.priceTo}
                  onChange={handleOnChange}
                  onKeyDown={handleKeyDown}
                  onFocus={() => setPriceSectionActive(true)}
                  onBlur={() => trySetPriceSectionActive()}
                  onKeyUp={handleKeyPress}
                  id="priceTo"
                />
              </div>
            </div>
          </div>
        </CombinedInput>

        {/* powierzchnia */}
        <CombinedInput id="floorage" isActive={isFloorageSectionActive}>
          <StyledInput
            type="text"
            placeholder={data.floorage}
            onClick={() => {
              setFloorageSectionActive(true)
              floorageRef.current.focus()
            }}
            className="placeholder"
          />
          <div className="children">
            <div
              style={{
                background:
                  "linear-gradient(90deg,#ce9638 5.35%,#eae69c 7.05%, #b6872d 52.34%)",
                border: !floorageRef?.current?.value
                  ? "1px solid #111"
                  : "1px solid transparent",
                width: "calc(47.1910%)",
                position: "absolute",
                opacity: isFloorageSectionActive ? "1" : "0",
                userSelect: isFloorageSectionActive ? "all" : "none",
              }}
            >
              <div
                style={{
                  background: "#111",
                  minHeight: "62px",
                }}
              >
                <StyledInput
                  type="text"
                  value={searchData.floorageFrom}
                  id="floorageFrom"
                  ref={floorageRef}
                  onChange={handleOnChange}
                  onKeyUp={handleKeyPress}
                  onKeyDown={handleKeyDown}
                  onFocus={() => setFloorageSectionActive(true)}
                  onBlur={() => trySetFloorageSectionActive()}
                  placeholder={data.from}
                  isActive={isFloorageSectionActive}
                />
              </div>
            </div>
            <div
              style={{
                background:
                  "linear-gradient(90deg,#ce9638 5.35%,#eae69c 7.05%, #b6872d 52.34%)",
                width: "calc(47.1910%)",
                position: "absolute",
                right: 0,
                visibility: isFloorageSectionActive ? "visible" : "hidden",
                border: !floorageRefTo?.current?.value
                  ? "1px solid #111"
                  : "1px solid transparent",
              }}
            >
              <div
                style={{
                  background: "#111",
                  minHeight: "62px",
                }}
              >
                <StyledInput
                  type="text"
                  ref={floorageRefTo}
                  id="floorageTo"
                  onChange={handleOnChange}
                  onKeyUp={handleKeyPress}
                  onKeyDown={handleKeyDown}
                  onFocus={() => setFloorageSectionActive(true)}
                  onBlur={() => trySetFloorageSectionActive()}
                  value={searchData.floorageTo}
                  placeholder={data.to}
                  isActive={isFloorageSectionActive}
                />
              </div>
            </div>
          </div>
        </CombinedInput>

        <GoldButton
          id="submit"
          onClick={() => {
            onSubmit()

            const el = document.getElementById("flats")

            if (el) {
              el.scrollIntoView(true)
            }
          }}
        >
          {data.search}
        </GoldButton>
      </Wrapper>
    </Main>
  )
}

export default SearchComponent
